import React from 'react';
// import gql from 'graphql-tag';
// import { graphql } from 'react-apollo';

import Markdown from 'react-markdown';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';

import Gallery from '../components/Gallery';

import RelatedPlaceCard from '../components/RelatedPlaceCard';
import RelatedLoreCard from '../components/RelatedLoreCard';
import RelatedInvestigationCard from '../components/RelatedInvestigationCard';
import RelatedTourCompanyCard from '../components/RelatedTourCompanyCard';
import RelatedBookCard from '../components/RelatedBookCard';



import Layout from '../components/Layout';
import SEO, {SEODescription, SEOImage} from '../components/SEO';

import { graphql } from 'gatsby';


const useStyles = makeStyles((theme) => ({
  articleContainer: {
    padding: theme.spacing(2),
  },
  section: {
    paddingTop: theme.spacing(2),
  },
}));

const Person = ({ data }) => {
  const classes = useStyles();
  const person = data.gcms.person;

  return (
    <Layout>

      <SEO 
        title={person.name}
        description={SEODescription(person.bio.text)}
        image={SEOImage(person.gallery[0].handle)}
        article={true}
      />

      <Paper className={classes.articleContainer}>
        <article>
          <Typography variant="h3" gutterBottom={true}>
            {person.name}
          </Typography>
          <Gallery gallery={person.gallery} />
          <Markdown source={person.bio.markdown} escapeHtml={false} />
          <Divider variant="middle" />
          {person.lores.length > 0 &&
            <section className={classes.section}>
              <Typography variant="h5" gutterBottom={true}>
                Stories about this person
              </Typography>
              <Grid container spacing={1}>
                {person.lores.map(lore => (
                  <RelatedLoreCard
                    key={lore.id}
                    lore={lore}
                  />
                ))}
              </Grid>
            </section>
          }

          {person.places.length > 0 &&
            <section className={classes.section}>
              <Typography variant="h5" gutterBottom={true}>
                Places this person haunts
              </Typography>
              <Grid container spacing={1}>
                {person.places.map(place => (
                  <RelatedPlaceCard
                    key={place.id}
                    place={place}
                  />
                ))}
              </Grid>
            </section>
          }

          {person.investigations.length > 0 &&
            <section className={classes.section}>
              <Typography variant="h5" gutterBottom={true}>
                Investigations into this person
              </Typography>
              <Grid container spacing={1}>
                {person.investigations.map(investigation => (
                  <RelatedInvestigationCard
                    key={investigation.id}
                    investigation={investigation}
                  />
                ))}
              </Grid>
            </section>
          }

          {person.tourCompanies.length > 0 &&
            <section className={classes.section}>
              <Typography variant="h5" gutterBottom={true}>
                Tours that talk this person
              </Typography>
              <Grid container spacing={1}>
                {person.tourCompanies.map(tourCompany => (
                  <RelatedTourCompanyCard
                    key={tourCompany.id}
                    tourCompany={tourCompany}
                  />
                ))}
              </Grid>
            </section>
          }

          {person.books.length > 0 &&
            <section className={classes.section}>
              <Typography variant="h5" gutterBottom={true}>
                Books with info on this person
              </Typography>
              <Grid container spacing={1}>
                {person.books.map(book => (
                  <RelatedBookCard
                    key={book.id}
                    book={book}
                  />
                ))}
              </Grid>
            </section>
          }

        </article>
      </Paper>
    </Layout>
  );
};

export const query = graphql`
  query singlePerson($slug: String!) {
    gcms {
      person(where: {slug: $slug}) {
        id
        name
        gallery {
          handle
          title
        }
        bio {
          markdown
          text
        }
        lores {
          id
          title
          slug
          gallery(first: 1) {
            handle
            title
          }
        }
        places {
          id
          name
          slug
          gallery(first: 1) {
            handle
            title
          }
        }
        investigations {
          id
          title
          slug
          image {
            handle
            title
          }
        }
        tourCompanies {
          id
          name
          slug
          image {
            handle
            title
          }
        }
        books {
          id
          title
          slug
          image {
            handle
            title
          }
        }
      }
    }
  }
`;

export default Person;


// const Person = ({ data: { loading, error, person } }) => {
//   const classes = useStyles();

//   if (error) return <h1>Error fetching the person!</h1>;
//   if (!loading) {
//     return (
//       <Paper className={classes.articleContainer}>
//         <article>
//           <Typography variant="h3" gutterBottom={true}>
//             {person.name}
//           </Typography>
//           <Gallery gallery={person.gallery} />
//           <Markdown source={person.bio.markdown} escapeHtml={false} />
//           <Divider variant="middle" />
//           {person.lores.length > 0 &&
//             <section className={classes.section}>
//               <Typography variant="h5" gutterBottom={true}>
//                 Stories about this person
//               </Typography>
//               <Grid container spacing={1}>
//                 {person.lores.map(lore => (
//                   <RelatedCard
//                     title={lore.title}
//                     link={`/lore/${lore.slug}`}
//                     imageHandle={lore.gallery[0].handle}
//                     imageTitle={lore.gallery[0].title}
//                   />
//                 ))}
//               </Grid>
//             </section>
//           }

//           {person.places.length > 0 &&
//             <section className={classes.section}>
//               <Typography variant="h5" gutterBottom={true}>
//                 Places this person haunts
//               </Typography>
//               <Grid container spacing={1}>
//                 {person.places.map(place => (
//                   <RelatedCard
//                     title={place.name}
//                     link={`/place/${place.slug}`}
//                     imageHandle={place.gallery[0].handle}
//                     imageTitle={place.gallery[0].title}
//                   />
//                 ))}
//               </Grid>
//             </section>
//           }

//           {person.investigations.length > 0 &&
//             <section className={classes.section}>
//               <Typography variant="h5" gutterBottom={true}>
//                 Investigations into this person
//               </Typography>
//               <Grid container spacing={1}>
//                 {person.investigations.map(investigation => (
//                   <RelatedCard
//                     title={investigation.title}
//                     link={`/investigation/${investigation.slug}`}
//                     imageHandle={investigation.image.handle}
//                     imageTitle={investigation.image.title}
//                   />
//                 ))}
//               </Grid>
//             </section>
//           }

//           {person.tourCompanies.length > 0 &&
//             <section className={classes.section}>
//               <Typography variant="h5" gutterBottom={true}>
//                 Tours that talk this person
//               </Typography>
//               <Grid container spacing={1}>
//                 {person.tourCompanies.map(tourCompany => (
//                   <RelatedCard
//                     title={tourCompany.name}
//                     link={`/tourcompany/${tourCompany.slug}`}
//                     imageHandle={tourCompany.image.handle}
//                     imageTitle={tourCompany.image.title}
//                   />
//                 ))}
//               </Grid>
//             </section>
//           }

//           {person.books.length > 0 &&
//             <section className={classes.section}>
//               <Typography variant="h5" gutterBottom={true}>
//                 Books with info on this person
//               </Typography>
//               <Grid container spacing={1}>
//                 {person.books.map(book => (
//                   <RelatedCard
//                     title={book.title}
//                     link={`/book/${book.slug}`}
//                     imageHandle={book.image.handle}
//                     imageTitle={book.image.title}
//                   />
//                 ))}
//               </Grid>
//             </section>
//           }

//         </article>
//       </Paper>
//     );
//   }
//   return <h2>Loading person...</h2>;
// };

// export const singlePerson = gql`
//   query singlePerson($slug: String!) {
//     person(where: {slug: $slug}) {
//       id
//       name
//       gallery {
//         handle
//         title
//       }
//       bio {
//         markdown
//       }
//       lores {
//         title
//         slug
//         gallery(first: 1) {
//           handle
//           title
//         }
//       }
//       places {
//         name
//         slug
//         gallery(first: 1) {
//           handle
//           title
//         }
//       }
//       investigations {
//         title
//         slug
//         image {
//           handle
//           title
//         }
//       }
//       tourCompanies {
//         name
//         slug
//         image {
//           handle
//           title
//         }
//       }
//       books {
//         title
//         slug
//         image {
//           handle
//           title
//         }
//       }
//     }
//   }
// `;

// export default graphql(singlePerson, {
//   options: ({ match }) => ({
//     variables: {
//       slug: match.params.slug,
//     },
//   }),
// })(Person);